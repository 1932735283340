<template>
  <div class="mobile-sidebar__root">
    <div
      :class="{'open':isOpen}"
      class="mobile-sidebar"
    >
      <slot />
    </div>
    <div
      v-if="isShowBackground"
      class="bg"
      @click="closeSidebar"
    />
  </div>
</template>

<script setup lang="ts">
import { useMobileMenu } from '~/composables/useMobileMenu';

const props = withDefaults(defineProps<{
	isOpen:boolean,
	offsetTop?:string,
	zIndex?:string | 'inherit' | '-1'| '5'| '10'| '20'|'25',
	width?:string | '10%'|'80%',
	bgZIndex?:string | 'inherit' | '-1'| '5'| '10'| '20'|'25',
	isShowBg?:boolean,
	bgColor?:string,
}>(), {
  isOpen: false,
  bgColor: '#8b8b8bb3',
  bgZIndex: '-1',
  offsetTop: '96px',
  zIndex: 'inherit',
  isShowBg: true,
  width: '80%'
});

watch(() => props.isOpen, (newValue) => {
  if (newValue) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = '';
  }
});

const { onMobileSidebarHandler } = useMobileMenu();
const closeSidebar = () => {
  onMobileSidebarHandler();
};

const isShowBackground = computed(() => (props.isOpen && props.isShowBg));

</script>

<style lang="scss">
.mobile-sidebar {
  display: none;
  width: v-bind(width);
  padding: 0;
}

@media(max-width: 1023px) {
  .mobile-sidebar__root {
    position: relative;

    & .bg {
      position: fixed;
      z-index: v-bind(bgZIndex);
      background: v-bind(bgColor);
      inset: v-bind(offsetTop) 0 0 0;
    }
  }

  .mobile-sidebar {
    display: flex;
    flex-direction: column;
    position: fixed;
    width: v-bind(width);
    inset: v-bind(offsetTop) 0 0 0;
    transition: all .3s linear;
    transform: translateX(-110%);
    z-index: v-bind(zIndex);
    overflow: hidden;

    &.open {
      transform: translateX(0);
      transition: all .3s linear;
      width: 100vw;
    }

  }
}

@media(max-width: 660px) {
  .mobile-sidebar {
    width: 100% ;
  }
}
</style>
